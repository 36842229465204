import type { Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme.js';
import animatePlugin from 'tailwindcss-animate';
import radixPlugin from 'tailwindcss-radix';

export default {
	content: ['./app/**/*.{ts,tsx,jsx,js}'],
	darkMode: 'class',
	theme: {
		container: {
			center: true,
			padding: '2rem',
			screens: {
				'2xl': '1400px',
				'md-h': { raw: '(min-height: 700px)' },
			},
		},
		extend: {
			colors: {
				'gray-background': '#ECECEC',
				'blue-gray': '#255A70',
				border: 'hsl(var(--border))',
				input: {
					DEFAULT: 'hsl(var(--input))',
					invalid: 'hsl(var(--input-invalid))',
				},
				ring: {
					DEFAULT: 'hsl(var(--ring))',
					invalid: 'hsl(var(--foreground-danger))',
				},
				background: 'hsl(var(--background))',
				foreground: {
					DEFAULT: 'hsl(var(--foreground))',
					danger: 'hsl(var(--foreground-danger))',
				},
				primary: {
					DEFAULT: 'hsl(var(--primary))',
					foreground: 'hsl(var(--primary-foreground))',
				},
				secondary: {
					DEFAULT: 'hsl(var(--secondary))',
					foreground: 'hsl(var(--secondary-foreground))',
				},
				destructive: {
					DEFAULT: 'hsl(var(--destructive))',
					foreground: 'hsl(var(--destructive-foreground))',
				},
				muted: {
					DEFAULT: 'hsl(var(--muted))',
					foreground: 'hsl(var(--muted-foreground))',
				},
				accent: {
					DEFAULT: 'hsl(var(--accent))',
					foreground: 'hsl(var(--accent-foreground))',
				},
				popover: {
					DEFAULT: 'hsl(var(--popover))',
					foreground: 'hsl(var(--popover-foreground))',
				},
				card: {
					DEFAULT: 'hsl(var(--card))',
					foreground: 'hsl(var(--card-foreground))',
				},
			},
			borderRadius: {
				lg: 'var(--radius)',
				md: 'calc(var(--radius) - 2px)',
				sm: 'calc(var(--radius) - 4px)',
			},
			fontFamily: {
				sans: ['var(--font-sans)', ...defaultTheme.fontFamily.sans],
			},
			fontSize: {
				// 1rem = 16px
				/** 80px size / 84px high / bold */
				mega: ['5rem', { lineHeight: '5.25rem', fontWeight: '700' }],
				/** 56px size / 62px high / bold */
				h1: ['3.5rem', { lineHeight: '3.875rem', fontWeight: '700' }],
				/** 40px size / 48px high / bold */
				h2: ['2.5rem', { lineHeight: '3rem', fontWeight: '700' }],
				/** 32px size / 36px high / bold */
				h3: ['2rem', { lineHeight: '2.25rem', fontWeight: '700' }],
				/** 28px size / 36px high / bold */
				h4: ['1.75rem', { lineHeight: '2.25rem', fontWeight: '700' }],
				/** 24px size / 32px high / bold */
				h5: ['1.5rem', { lineHeight: '2rem', fontWeight: '700' }],
				/** 16px size / 20px high / bold */
				h6: ['1rem', { lineHeight: '1.25rem', fontWeight: '700' }],

				/** 32px size / 36px high / normal */
				'body-2xl': ['2rem', { lineHeight: '2.25rem' }],
				/** 28px size / 36px high / normal */
				'body-xl': ['1.75rem', { lineHeight: '2.25rem' }],
				/** 24px size / 32px high / normal */
				'body-lg': ['1.5rem', { lineHeight: '2rem' }],
				/** 20px size / 28px high / normal */
				'body-md': ['1.25rem', { lineHeight: '1.75rem' }],
				/** 16px size / 20px high / normal */
				'body-sm': ['1rem', { lineHeight: '1.25rem' }],
				/** 14px size / 18px high / normal */
				'body-xs': ['0.875rem', { lineHeight: '1.125rem' }],
				/** 12px size / 16px high / normal */
				'body-2xs': ['0.75rem', { lineHeight: '1rem' }],

				/** 18px size / 24px high / semibold */
				caption: ['1.125rem', { lineHeight: '1.5rem', fontWeight: '600' }],
				/** 12px size / 16px high / bold */
				button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
			},
			keyframes: {
				'accordion-down': {
					from: { height: '0' },
					to: { height: 'var(--radix-accordion-content-height)' },
				},
				'accordion-up': {
					from: { height: 'var(--radix-accordion-content-height)' },
					to: { height: '0' },
				},
				'infinite-scroll': {
					from: { transform: 'translateY(26.75%)' },
					to: { transform: 'translateY(-24%)' },
				},
				'infinite-scroll-horizontal': {
					from: { transform: 'translateX(-25%)' },
					to: { transform: 'translateX(-75.35%)' },
				},
				blink: {
					'0%, 100%': { opacity: '1' },
					'50%': { opacity: '0.5' },
				},
				'caret-blink': {
					'0%,70%,100%': { opacity: '1' },
					'20%,50%': { opacity: '0' },
				},
			},
			animation: {
				'accordion-down': 'accordion-down 0.2s ease-out',
				'accordion-up': 'accordion-up 0.2s ease-out',
				'infinite-scroll': 'infinite-scroll 1s linear infinite',
				'infinite-scroll-horizontal':
					'infinite-scroll-horizontal 1s linear infinite',
				blink: 'blink 3s infinite',
				'caret-blink': 'caret-blink 1.25s ease-out infinite',
			},
			screens: {
				'2xs': '360px',
				xs: '480px',
			},
			boxShadow: {
				'outer-border': '0px 0px 17px -3px rgb(0 0 0 / 0.25)',
				'outer-border-sm': '0px 0px 6px 1px rgb(0 0 0 / 0.1)',
			},
			flex: {
				'1': '1 1 0%',
				'2': '2 2 0%',
				'3': '3 3 0%',
				'4': '4 4 0%',
				'5': '5 5 0%',
				'6': '6 6 0%',
				'7': '7 7 0%',
				'8': '8 8 0%',
				'9': '9 9 0%',
			},
			backgroundImage: {
				'hostU-dimmed':
					'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(0, 0, 0, 0.65) 99.36%);',
				'hostU-blue-gradient':
					'linear-gradient(93.96deg, #4BB5E2 17.14%, #2EBDB5 83.32%);',
				'hostU-reverse-blue-gradient':
					'linear-gradient(93.96deg, #2EBDB5 17.14%, #4BB5E2 83.32%);',
				'hostU-purple-gradient':
					'linear-gradient(93.96deg, #A94BE2 17.14%, #844BE0 83.32%);',
				'hostU-green-gradient':
					'linear-gradient(93.96deg, #63CC48 17.14%, #A4D53E 83.32%);',
				'hostU-orange-gradient':
					'linear-gradient(93.96deg, #EE8539 17.14%, #EDBC3F 83.32%);',
				'hostU-orange-gradient-opacity-50':
					'linear-gradient(93.96deg, rgb(238, 133, 57, 0.5) 17.14%, rgb(237, 188, 63, 0.5) 83.32%);',
				'hostU-blue-gradient-opacity-20':
					'linear-gradient(93.96deg, rgba(75, 181, 226, 0.2) 17.14%, rgba(46, 189, 181, 0.2) 83.32%)',
				'hostU-blue-gradient-opacity-30':
					'linear-gradient(93.96deg, rgba(75, 181, 226, 0.3) 17.14%, rgba(46, 189, 181, 0.3) 83.32%)',
				'hostU-blue-gradient-opacity-50':
					'linear-gradient(93.96deg, rgba(75, 181, 226, 0.5) 17.14%, rgba(46, 189, 181, 0.5) 83.32%)',
				'hostU-blue-gradient-opacity-75':
					'linear-gradient(93.96deg, rgba(75, 181, 226, 0.75) 17.14%, rgba(46, 189, 181, 0.75) 83.32%)',
				'hostU-gray-gradient':
					'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(224, 224, 224, 1) 100%)',
			},
		},
	},
	plugins: [animatePlugin, radixPlugin],
} satisfies Config;
